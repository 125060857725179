<template>
  <div class="text-left text-sm">
    <div class="mb-40 p-20 bg-background rounded">
      <div class="relative">
        <div class="absolute bottom-0 right-0">
          <slot name="top-opt"></slot>
        </div>
        <div class="text-xl font-bold mb-20 flex justify-between">
          <div>{{ dataSource.name }}</div>
          <div style="color: #fc8a00">{{ dataSource.budgetStr }}</div>
        </div>
        <a-space class="text-999 w-full" direction="vertical" :size="10">
          <div class="flex items-center">
            <div class="inline-block w-4 h-4 icon company-icon mr-1"></div>
            <span class="mr-2.5">发布方:</span>
            <span class="text-333">{{ dataSource.publisher }}</span>
          </div>
          <div class="flex items-center">
            <div class="inline-block w-4 h-4 icon time-icon mr-1"></div>
            <span class="mr-2.5">剩余天数:</span>
            <span class="text-333">{{ dataSource.lastDay }}</span>
          </div>
          <div class="flex items-center">
            <div class="inline-block w-4 h-4 icon calendar-icon mr-1"></div>
            <span class="mr-2.5">截至日期:</span>
            <span class="text-333">{{
              dataSource.endedTime | formatterTime("YYYY-MM-DD")
            }}</span>
          </div>
        </a-space>
      </div>
    </div>
    <div class="mb-40">
      <gf-title class="mb-20">需求详情</gf-title>
      <div class="mb-20">
        <div class="mb-10">需求所在行业：</div>
        <gf-tag-group>
          <gf-tag
            type="primary"
            v-for="(tag, index) in dataSource.industry"
            :key="index"
          >
            {{ tag.Name }}
          </gf-tag>
        </gf-tag-group>
      </div>
      <div class="mb-20">
        <div class="mb-10">需求应用范围：</div>
        <gf-tag-group>
          <gf-tag
            type="primary"
            v-for="(tag, index) in dataSource.scene"
            :key="index"
          >
            {{ tag.Name }}
          </gf-tag>
        </gf-tag-group>
      </div>
      <div class="mb-20">
        <div class="mb-10">需求应用区域：</div>
        <div>{{ dataSource.regionStr }}</div>
      </div>
    </div>
    <div class="mb-40">
      <gf-title class="mb-20">需求描述</gf-title>
      <div>{{ dataSource.desc }}</div>
    </div>
    <div class="mb-40">
      <gf-title class="mb-20">对服务商的要求</gf-title>
      <a-space direction="vertical" :size="10" class="w-full">
        <div v-for="(demand, index) in dataSource.demands" :key="index">
          {{ index + 1 }}、{{ demand.Key }}
        </div>
      </a-space>
    </div>
    <div class="mb-40" v-if="showContact">
      <gf-title class="mb-20">联系人信息</gf-title>
      <div class="flex justify-between text-999">
        <span>
          <span class="mr-2.5">姓名:</span>
          <span class="text-333">{{ dataSource.contact }}</span>
        </span>
        <span>
          <span class="mr-2.5">手机号码:</span>
          <span class="text-333">{{ dataSource.telephone }}</span>
        </span>
        <span>
          <span class="mr-2.5">职位:</span>
          <span class="text-333">{{ dataSource.position }}</span>
        </span>
      </div>
    </div>
    <div class="mb-40" v-if="showFeedback">
      <gf-title class="mb-20">专家审批意见</gf-title>
      <div class="text-999">
        {{ dataSource.feedback || "无" }}
      </div>
    </div>
    <div class="mb-40">
      <gf-title class="mb-20"
        >参与的服务商<span v-if="showSupply"
          >（{{ dataSource.count }}家）</span
        ></gf-title
      >
      <div class="flex flex-wrap" v-if="showSupply">
        <div
          v-for="item in supplys"
          :key="item.id"
          style="width: 132px; border: 1px solid #eee"
          class="border-eee p-10 rounded mr-20 mb-20 cursor-pointer relative"
          :class="{ selected: current.id === item.id }"
          @click="open(item)"
        >
          <a-checkbox
            v-if="allowChecked"
            @click.stop=""
            @change="$emit('change', item)"
            class="absolute right-10 top-10"
          ></a-checkbox>
          <div class="w-40 h-40 mx-auto mb-10">
            <img v-if="item.company.logo" :src="item.company.logo.url" alt="" />
          </div>
          <div class="ellipsis-2 h-40 text-center mb-20 text-sm">
            {{ item.company.companyName }}
          </div>
          <div class="text-999 text-xs text-center">查看详情</div>
        </div>
      </div>
      <div v-else>{{ dataSource.count }}家</div>
    </div>
    <div class="mb-40" v-if="selected.length">
      <gf-title class="mb-20">意向服务商</gf-title>
      <div class="flex flex-wrap">
        <div
          v-for="item in selected"
          :key="item.id"
          style="width: 132px; border: 1px solid #eee"
          class="border-eee p-10 rounded mr-20 mb-20 cursor-pointer relative"
          :class="{ selected: current.id === item.id }"
          @click="open(item)"
        >
          <div class="w-40 h-40 mx-auto mb-10">
            <img v-if="item.company.logo" :src="item.company.logo.url" alt="" />
          </div>
          <div class="ellipsis-2 h-40 text-center mb-20 text-sm">
            {{ item.company.companyName }}
          </div>
          <div class="text-999 text-xs text-center">查看详情</div>
        </div>
      </div>
    </div>

    <a-drawer
      @close="close"
      :visible="visible"
      title="服务商响应详情"
      :width="600"
    >
      <SupplyDisplay :showCompany="true" :dataSource="current" />
    </a-drawer>
  </div>
</template>

<script>
import SupplyDisplay from "./responseDisplay.vue";
export default {
  components: { SupplyDisplay },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    supplys: {
      type: Array,
      default: () => [],
    },
    allowChecked: Boolean,
    showSupply: {
      type: Boolean,
      default: true,
    },
    showContact: {
      type: Boolean,
      default: true,
    },
    showFeedback: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isManager() {
      return this.$store.getters["user/isManager"];
    },
    isExpert() {
      return this.$store.getters["user/isExpert"];
    },
    selected() {
      return this.supplys.filter((item) => item.selected);
    },
  },
  data() {
    return {
      current: {},
      visible: false,
    };
  },
  methods: {
    open(item) {
      this.visible = true;
      this.current = item;
    },
    close() {
      this.current = {};
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.selected {
  border: 1px solid #0060ed !important;
}
.ok {
  border: 1px solid green !important;
}
</style>