<template>
  <div class="text-left p-20">
    <div class="mb-40">
      <a-form-model
        ref="container"
        :model="model"
        :labelCol="{ span: 5 }"
        :wrapperCol="{ span: 11 }"
        labelAlign="left"
        :colon="false"
      >
        <!-- 1 -->
        <div class="mb-40">
          <div class="mb-20">
            <gf-title>申报基础信息</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="value1"
              label="申报单位"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value1"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value2"
              label="推荐机构"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value2"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value3"
              label="申报日期"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <a-date-picker
                :disabled="disabled"
                v-model="model.value3"
              ></a-date-picker>
            </gf-form-item>
          </div>
        </div>
        <!-- 4 -->
        <div class="mb-40">
          <div class="mb-20">
            <gf-title>企业基本情况</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="logo"
              label="企业logo"
              :wrapperCol="{ span: 7 }"
              :rules="[
                {
                  required: true,
                  message: '请上传',
                  trigger: ['change', 'blur'],
                },
                { validator: validator },
              ]"
            >
              <picture-upload
                v-model="model.logo"
                :scale="{ w: 1, h: 1 }"
                :width="338"
                :height="160"
                accept=".png,.jpg,.jpeg"
                :size="5"
                field="11"
              ></picture-upload>
            </gf-form-item>
            <gf-form-item
              prop="value4"
              label="企业名称（全称）"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value4"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value5"
              label="成立时间"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <a-date-picker
                :disabled="disabled"
                v-model="model.value5"
              ></a-date-picker>
            </gf-form-item>
            <gf-form-item
              prop="value6"
              :rules="{
                required: true,
                message: '请选择',
                trigger: ['change', 'blur'],
              }"
              label="企业性质"
              :wrapperCol="{ span: 7 }"
            >
              <a-select :disabled="disabled" v-model="model.value6">
                <a-select-option :value="8">国家机关</a-select-option>
                <a-select-option :value="5"
                  >事业单位（含高校及研究机构）</a-select-option
                >
                <a-select-option :value="9">社会团体</a-select-option>
                <a-select-option :value="1">国有企业</a-select-option>
                <a-select-option :value="2">民营企业</a-select-option>
                <a-select-option :value="4">外资企业</a-select-option>
              </a-select>
            </gf-form-item>
            <gf-form-item
              prop="value7"
              :rules="{
                required: true,
                message: '请选择',
                trigger: ['change', 'blur'],
              }"
              label="企业规模"
              :wrapperCol="{ span: 7 }"
            >
              <a-select :disabled="disabled" v-model="model.value7">
                <a-select-option :value="1">大型</a-select-option>
                <a-select-option :value="2">中型</a-select-option>
                <a-select-option :value="3">小型</a-select-option>
              </a-select>
            </gf-form-item>
            <gf-form-item
              prop="value8"
              label="员工人数"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <a-input-number
                :precision="0"
                :disabled="disabled"
                v-model="model.value8"
              ></a-input-number>
            </gf-form-item>
            <gf-form-item
              prop="value9"
              :rules="{
                required: true,
                message: '请选择',
                trigger: ['change', 'blur'],
              }"
              label="是否上市"
              :wrapperCol="{ span: 7 }"
            >
              <a-select :disabled="disabled" v-model="model.value9">
                <a-select-option :value="1">是</a-select-option>
                <a-select-option :value="0">否</a-select-option>
              </a-select>
            </gf-form-item>
            <gf-form-item
              prop="value10"
              label="注册地/总部所在地"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value10"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value11"
              label="2020营业额（万元）"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <TenThousandNumber
                :disabled="disabled"
                v-model="model.value11"
              ></TenThousandNumber>
            </gf-form-item>
            <gf-form-item
              prop="value12"
              label="2021预估营业额（万元）"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <TenThousandNumber
                :disabled="disabled"
                v-model="model.value12"
              ></TenThousandNumber>
            </gf-form-item>
            <gf-form-item
              prop="value13"
              label="企业官网"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value13"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value14"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="产品及主营业务"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="请在此输入产品及主营业务"
                v-model="model.value14"
              ></gf-re-textarea>
            </gf-form-item>
            <gf-form-item
              prop="value15"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="融资经历"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                :placeholder="`最近一次/或一年内融资（时间、轮次、金额、投资方）
填写示例：2021-10-21- A轮融资-1200万-青云创投`"
                v-model="model.value15"
              ></gf-re-textarea>
            </gf-form-item>
          </div>
        </div>
        <!-- 16 -->
        <div class="mb-40">
          <div class="mb-20">
            <gf-title>联系人信息</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="value16"
              label="姓名"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value16"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value17"
              label="手机"
              :wrapperCol="{ span: 7 }"
              :rules="[
                {
                  validator: validateTel,
                  message: '请输入正确格式的手机号码',
                  trigger: ['change', 'blur'],
                },
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value17"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value18"
              label="职务"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value18"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value19"
              label="邮箱"
              :wrapperCol="{ span: 7 }"
              :rules="[
                {
                  validator: validateEmail,
                  message: '请输入正确格式的邮箱',
                  trigger: ['change', 'blur'],
                },
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value19"
              ></gf-re-input>
            </gf-form-item>
          </div>
        </div>
        <!-- 20 -->
        <div class="mb-40">
          <div class="mb-20">
            <gf-title>自荐/推荐理由</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="value20"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="自荐/推荐理由"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="请在此输入自荐理由"
                v-model="model.value20"
              ></gf-re-textarea>
            </gf-form-item>
          </div>
        </div>
        <!-- 21 -->
        <div class="mb-40">
          <div class="mb-20">
            <gf-title>方案内容</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="value21"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 3000,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="方案内容"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="3000"
                placeholder="根据所选赛道特征和要求，提供不限于方案简介、市场痛点分析、问题描述、针对的行业和应用场景、创新技术或自主知识产权技术/自主可控技术、转型特色、实际成效与客户见证、市场扩展潜力、新模式新应用等"
                v-model="model.value21"
              ></gf-re-textarea>
            </gf-form-item>
            <gf-form-item label="证实性材料" tooltip="不限于详细产品与解决方案介绍、典型客户案例、知识产权或专利证书、介绍视频等">
              <file-upload
                v-if="model.value22"
                :size="100"
                v-model="model.value22"
              ></file-upload>
            </gf-form-item>
          </div>
        </div>
        <!-- 23 -->
        <div>
          <div class="mb-20">
            <gf-title>主管部门/行业协会推荐意见</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="value23"
              label="主管部门/行业协会名称"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value23"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value24"
              label="联系人"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value24"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              :rules="{
                validator: validateTel,
                message: '请输入正确格式的手机号码',
                trigger: ['change', 'blur'],
              }"
              prop="value25"
              label="手机"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value25"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value26"
              label="传真"
              :wrapperCol="{ span: 7 }"
              :rules="{
                validator: validateFax,
                message: '请输入正确格式的传真',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value26"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item prop="value27" label="地址" :wrapperCol="{ span: 7 }">
              <gf-re-input
                :disabled="disabled"
                v-model="model.value27"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              :rules="{
                validator: validateZip,
                message: '请输入正确格式的邮政编码',
                trigger: ['change', 'blur'],
              }"
              prop="value28"
              label="邮编"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value28"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value28_1"
              label="邮箱"
              :rules="{
                validator: validateEmail,
                message: '请输入正确格式的邮箱',
                trigger: ['change', 'blur'],
              }"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value28_1"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value29"
              :rules="[
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="主管部门/行业协会简介"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="请在此输入主管部门/行业协会简介"
                v-model="model.value29"
              ></gf-re-textarea>
            </gf-form-item>
            <gf-form-item
              prop="value30"
              :rules="[
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="推荐意见"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="围绕申报单位相关成果、行业影响力等展开"
                v-model="model.value30"
              ></gf-re-textarea>
            </gf-form-item>
          </div>
        </div>
        <!-- 31 -->
        <div>
          <div class="mb-20">
            <gf-title>专家推荐意见</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="value31"
              label="专家姓名"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value31"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value32"
              label="所在单位"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value32"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item prop="value33" label="职位" :wrapperCol="{ span: 7 }">
              <gf-re-input
                :disabled="disabled"
                v-model="model.value33"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item prop="value34" label="地址" :wrapperCol="{ span: 7 }">
              <gf-re-input
                :disabled="disabled"
                v-model="model.value34"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              :rules="{
                validator: validateZip,
                message: '请输入正确格式的邮政编码',
                trigger: ['change', 'blur'],
              }"
              prop="value35"
              label="邮编"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value35"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value36"
              :rules="[
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="专家个人介绍"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="请在此输入专家个人简介"
                v-model="model.value36"
              ></gf-re-textarea>
            </gf-form-item>
            <gf-form-item
              prop="value37"
              :rules="[
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="推荐意见"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="围绕申报单位相关成果、行业影响力等展开"
                v-model="model.value37"
              ></gf-re-textarea>
            </gf-form-item>
          </div>
        </div>
      </a-form-model>
    </div>
    <div class="text-center">
      <slot v-bind="{ validate }" />
    </div>
  </div>
</template>

<script>
import {
  validateArrayCount,
  validateFax,
  validateEmail,
  validateTel,
  validateZip,
  validatePhone,
} from "@/utils/validator";
const validateSceneCount = validateArrayCount(0, 3);
export default {
  props: {
    rules: {
      type: Object,
      default: () => ({}),
    },
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    disabled: Boolean,
  },
  created() {
    this.model = { ...this.dataSource };
  },
  data() {
    return {
      model: {},
      scale: [
        { ID: 1, Name: "大型" },
        { ID: 2, Name: "中型" },
        { ID: 3, Name: "小型" },
      ],
    };
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.container.validate((valid) => {
          if (valid) {
            resolve(this.restore({ ...this.model }));
          } else {
            reject();
          }
        });
      });
    },
    restore(data) {
      const temp = {};

      temp.Company = {
        Name: data.value4,
        RegisterTime: data.value5,
        Property: this.options.CompanyProperty.find(
          (item) => item.ID === data.value6
        ),
        Scale: this.scale.find((item) => item.ID === data.value7),
        Employees: data.value8,
        Listed: !!data.value9,
        RegisterAddress: data.value10,
        Revenue2020: data.value11,
        Revenue2021: data.value12,
        Website: data.value13,
        Product: data.value14,
        Financing: data.value15,
        Contact: {
          Name: data.value16,
          Phone: data.value17,
          Position: data.value18,
          Email: data.value19,
        },
        Reason: data.value20,
        Plan: data.value21,
        Attachments: data.value22,
      };
      temp.CompanyName = data.value1;
      temp.Referrer = data.value2;
      temp.Recommendation = {
        Name: data.value23,
        Contact: data.value24,
        Phone: data.value25,
        Fax: data.value26,
        Address: data.value27,
        ZipCode: data.value28,
        Email: data.value28_1,
        Intro: data.value29,
        Comment: data.value30,
      };
      temp.Expert = {
        Name: data.value31,
        Department: data.value32,
        Position: data.value33,
        Address: data.value34,
        ZipCode: data.value35,
        Intro: data.value36,
        Comment: data.value37,
      };
      temp.Time = data.value3;
      temp.logo = data.logo;

      return temp;
    },
    validateZip(...rest) {
      return validateZip(...rest);
    },
    validatePhone(...rest) {
      return validatePhone(...rest);
    },
    validateEmail(...rest) {
      return validateEmail(...rest);
    },
    validateTel(...rest) {
      return validateTel(...rest);
    },
    validateFax(...rest) {
      return validateFax(...rest);
    },
    validator(rule, value, callback) {
      console.log(value);
      if (value && value.ID) {
        callback();
      } else {
        callback(new Error("请上传"));
      }
    },
  },
  watch: {
    dataSource(val) {
      const temp = Object.assign(this.model, val);
      this.model = { ...temp };
    },
  },
};
</script>