var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left p-20"},[_c('div',{staticClass:"mb-40"},[_c('a-form-model',{ref:"container",attrs:{"model":_vm.model,"labelCol":{ span: 5 },"wrapperCol":{ span: 11 },"labelAlign":"left","colon":false}},[_c('div',{staticClass:"mb-40"},[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("申报基础信息")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"value1","label":"申报单位","wrapperCol":{ span: 7 },"rules":{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value1),callback:function ($$v) {_vm.$set(_vm.model, "value1", $$v)},expression:"model.value1"}})],1),_c('gf-form-item',{attrs:{"prop":"value2","label":"推荐机构","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value2),callback:function ($$v) {_vm.$set(_vm.model, "value2", $$v)},expression:"model.value2"}})],1),_c('gf-form-item',{attrs:{"prop":"value3","label":"申报日期","wrapperCol":{ span: 7 },"rules":{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }}},[_c('a-date-picker',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value3),callback:function ($$v) {_vm.$set(_vm.model, "value3", $$v)},expression:"model.value3"}})],1)],1)]),_c('div',{staticClass:"mb-40"},[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("企业基本情况")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"logo","label":"企业logo","wrapperCol":{ span: 7 },"rules":[
                {
                  required: true,
                  message: '请上传',
                  trigger: ['change', 'blur'],
                },
                { validator: _vm.validator } ]}},[_c('picture-upload',{attrs:{"scale":{ w: 1, h: 1 },"width":338,"height":160,"accept":".png,.jpg,.jpeg","size":5,"field":"11"},model:{value:(_vm.model.logo),callback:function ($$v) {_vm.$set(_vm.model, "logo", $$v)},expression:"model.logo"}})],1),_c('gf-form-item',{attrs:{"prop":"value4","label":"企业名称（全称）","wrapperCol":{ span: 7 },"rules":{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value4),callback:function ($$v) {_vm.$set(_vm.model, "value4", $$v)},expression:"model.value4"}})],1),_c('gf-form-item',{attrs:{"prop":"value5","label":"成立时间","wrapperCol":{ span: 7 },"rules":{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }}},[_c('a-date-picker',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value5),callback:function ($$v) {_vm.$set(_vm.model, "value5", $$v)},expression:"model.value5"}})],1),_c('gf-form-item',{attrs:{"prop":"value6","rules":{
                required: true,
                message: '请选择',
                trigger: ['change', 'blur'],
              },"label":"企业性质","wrapperCol":{ span: 7 }}},[_c('a-select',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value6),callback:function ($$v) {_vm.$set(_vm.model, "value6", $$v)},expression:"model.value6"}},[_c('a-select-option',{attrs:{"value":8}},[_vm._v("国家机关")]),_c('a-select-option',{attrs:{"value":5}},[_vm._v("事业单位（含高校及研究机构）")]),_c('a-select-option',{attrs:{"value":9}},[_vm._v("社会团体")]),_c('a-select-option',{attrs:{"value":1}},[_vm._v("国有企业")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v("民营企业")]),_c('a-select-option',{attrs:{"value":4}},[_vm._v("外资企业")])],1)],1),_c('gf-form-item',{attrs:{"prop":"value7","rules":{
                required: true,
                message: '请选择',
                trigger: ['change', 'blur'],
              },"label":"企业规模","wrapperCol":{ span: 7 }}},[_c('a-select',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value7),callback:function ($$v) {_vm.$set(_vm.model, "value7", $$v)},expression:"model.value7"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v("大型")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v("中型")]),_c('a-select-option',{attrs:{"value":3}},[_vm._v("小型")])],1)],1),_c('gf-form-item',{attrs:{"prop":"value8","label":"员工人数","wrapperCol":{ span: 7 },"rules":{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }}},[_c('a-input-number',{attrs:{"precision":0,"disabled":_vm.disabled},model:{value:(_vm.model.value8),callback:function ($$v) {_vm.$set(_vm.model, "value8", $$v)},expression:"model.value8"}})],1),_c('gf-form-item',{attrs:{"prop":"value9","rules":{
                required: true,
                message: '请选择',
                trigger: ['change', 'blur'],
              },"label":"是否上市","wrapperCol":{ span: 7 }}},[_c('a-select',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value9),callback:function ($$v) {_vm.$set(_vm.model, "value9", $$v)},expression:"model.value9"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v("是")]),_c('a-select-option',{attrs:{"value":0}},[_vm._v("否")])],1)],1),_c('gf-form-item',{attrs:{"prop":"value10","label":"注册地/总部所在地","wrapperCol":{ span: 7 },"rules":{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value10),callback:function ($$v) {_vm.$set(_vm.model, "value10", $$v)},expression:"model.value10"}})],1),_c('gf-form-item',{attrs:{"prop":"value11","label":"2020营业额（万元）","wrapperCol":{ span: 7 },"rules":{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }}},[_c('TenThousandNumber',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value11),callback:function ($$v) {_vm.$set(_vm.model, "value11", $$v)},expression:"model.value11"}})],1),_c('gf-form-item',{attrs:{"prop":"value12","label":"2021预估营业额（万元）","wrapperCol":{ span: 7 },"rules":{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }}},[_c('TenThousandNumber',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value12),callback:function ($$v) {_vm.$set(_vm.model, "value12", $$v)},expression:"model.value12"}})],1),_c('gf-form-item',{attrs:{"prop":"value13","label":"企业官网","wrapperCol":{ span: 7 },"rules":{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value13),callback:function ($$v) {_vm.$set(_vm.model, "value13", $$v)},expression:"model.value13"}})],1),_c('gf-form-item',{attrs:{"prop":"value14","rules":[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                } ],"label":"产品及主营业务"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"请在此输入产品及主营业务"},model:{value:(_vm.model.value14),callback:function ($$v) {_vm.$set(_vm.model, "value14", $$v)},expression:"model.value14"}})],1),_c('gf-form-item',{attrs:{"prop":"value15","rules":[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                } ],"label":"融资经历"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"最近一次/或一年内融资（时间、轮次、金额、投资方）\n填写示例：2021-10-21- A轮融资-1200万-青云创投"},model:{value:(_vm.model.value15),callback:function ($$v) {_vm.$set(_vm.model, "value15", $$v)},expression:"model.value15"}})],1)],1)]),_c('div',{staticClass:"mb-40"},[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("联系人信息")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"value16","label":"姓名","wrapperCol":{ span: 7 },"rules":{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value16),callback:function ($$v) {_vm.$set(_vm.model, "value16", $$v)},expression:"model.value16"}})],1),_c('gf-form-item',{attrs:{"prop":"value17","label":"手机","wrapperCol":{ span: 7 },"rules":[
                {
                  validator: _vm.validateTel,
                  message: '请输入正确格式的手机号码',
                  trigger: ['change', 'blur'],
                },
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                } ]}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value17),callback:function ($$v) {_vm.$set(_vm.model, "value17", $$v)},expression:"model.value17"}})],1),_c('gf-form-item',{attrs:{"prop":"value18","label":"职务","wrapperCol":{ span: 7 },"rules":{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value18),callback:function ($$v) {_vm.$set(_vm.model, "value18", $$v)},expression:"model.value18"}})],1),_c('gf-form-item',{attrs:{"prop":"value19","label":"邮箱","wrapperCol":{ span: 7 },"rules":[
                {
                  validator: _vm.validateEmail,
                  message: '请输入正确格式的邮箱',
                  trigger: ['change', 'blur'],
                },
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                } ]}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value19),callback:function ($$v) {_vm.$set(_vm.model, "value19", $$v)},expression:"model.value19"}})],1)],1)]),_c('div',{staticClass:"mb-40"},[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("自荐/推荐理由")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"value20","rules":[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                } ],"label":"自荐/推荐理由"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"请在此输入自荐理由"},model:{value:(_vm.model.value20),callback:function ($$v) {_vm.$set(_vm.model, "value20", $$v)},expression:"model.value20"}})],1)],1)]),_c('div',{staticClass:"mb-40"},[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("方案内容")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"value21","rules":[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 3000,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                } ],"label":"方案内容"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":3000,"placeholder":"根据所选赛道特征和要求，提供不限于方案简介、市场痛点分析、问题描述、针对的行业和应用场景、创新技术或自主知识产权技术/自主可控技术、转型特色、实际成效与客户见证、市场扩展潜力、新模式新应用等"},model:{value:(_vm.model.value21),callback:function ($$v) {_vm.$set(_vm.model, "value21", $$v)},expression:"model.value21"}})],1),_c('gf-form-item',{attrs:{"label":"证实性材料","tooltip":"不限于详细产品与解决方案介绍、典型客户案例、知识产权或专利证书、介绍视频等"}},[(_vm.model.value22)?_c('file-upload',{attrs:{"size":100},model:{value:(_vm.model.value22),callback:function ($$v) {_vm.$set(_vm.model, "value22", $$v)},expression:"model.value22"}}):_vm._e()],1)],1)]),_c('div',[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("主管部门/行业协会推荐意见")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"value23","label":"主管部门/行业协会名称","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value23),callback:function ($$v) {_vm.$set(_vm.model, "value23", $$v)},expression:"model.value23"}})],1),_c('gf-form-item',{attrs:{"prop":"value24","label":"联系人","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value24),callback:function ($$v) {_vm.$set(_vm.model, "value24", $$v)},expression:"model.value24"}})],1),_c('gf-form-item',{attrs:{"rules":{
                validator: _vm.validateTel,
                message: '请输入正确格式的手机号码',
                trigger: ['change', 'blur'],
              },"prop":"value25","label":"手机","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value25),callback:function ($$v) {_vm.$set(_vm.model, "value25", $$v)},expression:"model.value25"}})],1),_c('gf-form-item',{attrs:{"prop":"value26","label":"传真","wrapperCol":{ span: 7 },"rules":{
                validator: _vm.validateFax,
                message: '请输入正确格式的传真',
                trigger: ['change', 'blur'],
              }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value26),callback:function ($$v) {_vm.$set(_vm.model, "value26", $$v)},expression:"model.value26"}})],1),_c('gf-form-item',{attrs:{"prop":"value27","label":"地址","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value27),callback:function ($$v) {_vm.$set(_vm.model, "value27", $$v)},expression:"model.value27"}})],1),_c('gf-form-item',{attrs:{"rules":{
                validator: _vm.validateZip,
                message: '请输入正确格式的邮政编码',
                trigger: ['change', 'blur'],
              },"prop":"value28","label":"邮编","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value28),callback:function ($$v) {_vm.$set(_vm.model, "value28", $$v)},expression:"model.value28"}})],1),_c('gf-form-item',{attrs:{"prop":"value28_1","label":"邮箱","rules":{
                validator: _vm.validateEmail,
                message: '请输入正确格式的邮箱',
                trigger: ['change', 'blur'],
              },"wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value28_1),callback:function ($$v) {_vm.$set(_vm.model, "value28_1", $$v)},expression:"model.value28_1"}})],1),_c('gf-form-item',{attrs:{"prop":"value29","rules":[
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                } ],"label":"主管部门/行业协会简介"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"请在此输入主管部门/行业协会简介"},model:{value:(_vm.model.value29),callback:function ($$v) {_vm.$set(_vm.model, "value29", $$v)},expression:"model.value29"}})],1),_c('gf-form-item',{attrs:{"prop":"value30","rules":[
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                } ],"label":"推荐意见"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"围绕申报单位相关成果、行业影响力等展开"},model:{value:(_vm.model.value30),callback:function ($$v) {_vm.$set(_vm.model, "value30", $$v)},expression:"model.value30"}})],1)],1)]),_c('div',[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("专家推荐意见")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"value31","label":"专家姓名","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value31),callback:function ($$v) {_vm.$set(_vm.model, "value31", $$v)},expression:"model.value31"}})],1),_c('gf-form-item',{attrs:{"prop":"value32","label":"所在单位","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value32),callback:function ($$v) {_vm.$set(_vm.model, "value32", $$v)},expression:"model.value32"}})],1),_c('gf-form-item',{attrs:{"prop":"value33","label":"职位","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value33),callback:function ($$v) {_vm.$set(_vm.model, "value33", $$v)},expression:"model.value33"}})],1),_c('gf-form-item',{attrs:{"prop":"value34","label":"地址","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value34),callback:function ($$v) {_vm.$set(_vm.model, "value34", $$v)},expression:"model.value34"}})],1),_c('gf-form-item',{attrs:{"rules":{
                validator: _vm.validateZip,
                message: '请输入正确格式的邮政编码',
                trigger: ['change', 'blur'],
              },"prop":"value35","label":"邮编","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value35),callback:function ($$v) {_vm.$set(_vm.model, "value35", $$v)},expression:"model.value35"}})],1),_c('gf-form-item',{attrs:{"prop":"value36","rules":[
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                } ],"label":"专家个人介绍"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"请在此输入专家个人简介"},model:{value:(_vm.model.value36),callback:function ($$v) {_vm.$set(_vm.model, "value36", $$v)},expression:"model.value36"}})],1),_c('gf-form-item',{attrs:{"prop":"value37","rules":[
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                } ],"label":"推荐意见"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"围绕申报单位相关成果、行业影响力等展开"},model:{value:(_vm.model.value37),callback:function ($$v) {_vm.$set(_vm.model, "value37", $$v)},expression:"model.value37"}})],1)],1)])])],1),_c('div',{staticClass:"text-center"},[_vm._t("default",null,null,{ validate: _vm.validate })],2)])}
var staticRenderFns = []

export { render, staticRenderFns }